.document-card {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 24px 34px 19px 17px;
  margin-right: 24px;
  margin-top: 42px;

  img {
    width: 190px;
    height: 107px;
    object-fit: contain;
  }
  .button-card {
    height: 39px;
    width: 152px;
    left: 0px;
    top: 0px;
    border-radius: 38px;
  }
}
