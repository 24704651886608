.label {
  font-size: 12px;
  justify-content: center;
}
.container-color {
  height: 56px;
  width: 252px;
  left: 578px;
  top: 1065px;
  border-radius: 8px;
}
.driver-main {
  margin-left: 34px;
  padding: 0;
}

.driver-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;

  .actions {
    margin-left: 28px;

    label {
      margin-top: 7px;
    }
  }

  .tags {
    width: 80px;
    margin-right: 30px;
  }

  .status-button {
    margin-left: 20px;
  }

  .account-status {
    label {
      width: 200px;
    }
  }

  label {
    padding: 0;
    margin: 0;
  }
}

.document-section {
  display: flex;
  flex-wrap: wrap;
  .document {
    width: 33.33%;
  }
}
