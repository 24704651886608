.user-info {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  background: white;

  .sub-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .personal-info {
    h2 {
      margin: 17px 0 46px;
      font-size: 16px;
    }
  }

  .personal-info-container {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
    row-gap: 30px;

    h3 {
      margin: 17px 0 46px;
      font-size: 14px;
      font-weight: 400;
    }
    .dat {
      font-size: 14px;
      font-weight: 500;
    }

    p {
      font-size: 14px;
      font-weight: 300;
    }
  }
}
