/* ==============
  Email
===================*/
.email-leftbar {
  float: left;
  padding: 20px;
  border-radius: 5px;
  width: 30%;
  max-width: 390px;
  max-height: 1130px;
  position: fixed;
  z-index: 1;
  overflow-x: hidden;
  height: calc(100% - 90px);
  /* Disable horizontal scroll */

}

.email-rightbar {
  margin-left: 400px;
  padding: 0px 10px;


}

.h4 {
  font-family: "Rubik", sans-serif;
  margin-top: 25px;
  margin-left: 63px;
  margin-right: 37px;
  margin-bottom: 27px;
}

.chat-user-box {
  p.user-title {
    color: $dark;
    font-weight: 500;
  }

  p {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .email-leftbar {
    float: none;
    width: 100%;
  }

  .email-rightbar {
    margin: 0;
  }
}

.mail-list {
  a {
    display: block;
    color: $gray-600 !important;
    line-height: 24px;
    padding: 8px 5px;

    &.active {
      color: $danger !important;
      font-weight: 500;
    }
  }
}

.mail-list .nav-tabs-custom .nav-item .nav-link::after {
  content: none;
}

ul.ba {
  list-style-type: none;
}

.message-list {
  display: block;
  padding-left: 0;

  li {
    position: relative;
    display: block;
    height: 50px;
    line-height: 50px;
    cursor: default;
    transition-duration: 0.3s;

    a {
      color: $gray-600;
    }

    &:hover {
      background: $gray-300;
      transition-duration: 0.05s;
    }

    .col-mail {
      float: left;
      position: relative;
    }

    .col-mail-1 {
      width: 320px;

      .star-toggle,
      .checkbox-wrapper-mail,
      .dot {
        display: block;
        float: left;
      }

      .dot {
        border: 4px solid transparent;
        border-radius: 100px;
        margin: 22px 26px 0;
        height: 0;
        width: 0;
        line-height: 0;
        font-size: 0;
      }

      .checkbox-wrapper-mail {
        margin: 15px 10px 0 20px;
      }

      .star-toggle {
        margin-top: 18px;
        margin-left: 5px;
      }

      .title {
        position: absolute;
        top: 0;
        left: 110px;
        right: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-bottom: 0;
      }
    }

    .col-mail-2 {
      position: absolute;
      top: 0;
      left: 320px;
      right: 0;
      bottom: 0;

      .subject,
      .date {
        position: absolute;
        top: 0;
      }

      .subject {
        left: 0;
        right: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .date {
        right: 0;
        width: 170px;
        padding-left: 80px;
      }
    }

    &.active,
    &.active:hover {
      box-shadow: inset 3px 0 0 $primary;
    }

    &.unread {
      background-color: $gray-300;
      font-weight: 500;
      color: darken($dark, 5%);

      a {
        color: darken($dark, 5%);
        font-weight: 500;
      }
    }
  }



  .checkbox-wrapper-mail {
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    display: inline-block;
    box-shadow: inset 0 0 0 1px $gray-400;
    border-radius: 1px;

    input {
      opacity: 0;
      cursor: pointer;
    }

    input:checked~label {
      opacity: 1;
    }

    label {
      position: absolute;
      height: 20px;
      width: 20px;
      left: 0;
      cursor: pointer;
      opacity: 0;
      margin-bottom: 0;
      transition-duration: 0.05s;
      top: 0;

      &:before {
        content: "\F012C";
        font-family: "Rubik";
        top: 0;
        height: 20px;
        color: darken($dark, 5%);
        width: 20px;
        position: absolute;
        margin-top: -16px;
        left: 4px;
        font-size: 13px;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .message-list li .col-mail-1 {
    width: 200px;
  }
}

i {
  font-size: 1.40rem;
  margin-left: 4px;
}

.iconlarge {
  font-size: 2.40rem;
  margin-left: 4px;
  top: 2.10009765625px;
  border-radius: 0px;

}

h5 {
  height: 30px;
  align-content: center;
  margin-top: 0.5em;

}

.label-button {
  height: 19px;
  width: 129px;
  left: 97px;
  top: 16px;
  border-radius: nullpx;



}

.icon-small {
  height: 20px;
  width: 20px;
  left: 2px;
  top: 2px;
  border-radius: 0px;

}

.btn-round {
  border-radius: 38px;
  width: 322px;
  height: 48px;
}

.btn-round-card {
  border-radius: 38px;
  width: 152px;
  height: 39px;
  box-shadow: 2px 2px 2px black;
}

.btn-round-selector {
  border-radius: 10px;
  width: 146px;
  height: 30px;
}

.label-top {
  margin-top: 24px;
  margin-bottom: 27px;

}

.label-nav {
  font-size: 1em;

}

.sub-label-nav {
  font-size: 0.7em;
  margin-bottom: 34px;
}

.icon-xs {
  width: 2px;
  height: 2px;

}
